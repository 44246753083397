/* eslint-disable no-unused-vars */
import { axiosApiInstance } from "@/plugins/axios.js"
import moment from "moment";

function getUsers(){
  axiosApiInstance({
    url:process.env.VUE_APP_BACKEND+"/settings/sysusers/"
  }).then(res=>{
    this.items = res.data.map(el=>{
      if("last_login" in el){
        let last_login = el["last_login"] + 'Z'
        el["last_login"] = moment(last_login).fromNow(true) + " | " + moment(last_login).format("LLL");
        return el;
      }
      el["last_login"] ="NA";
      return el;
    }).filter(el=>el.role!="administrator");
  });
}
function saveUser(user){
  axiosApiInstance({
    url:process.env.VUE_APP_BACKEND+"/settings/sysusers/",
    method:'post',
    data:user
  }).then(()=>{
    this.$bvModal.hide("register-user");
  });
}

/**
 * 
 * @param {string} user_id 
 */
function deleteUser(user_id){
  return axiosApiInstance.delete(
    process.env.VUE_APP_BACKEND+"/settings/sysusers/"+user_id
  );
}
export { getUsers, saveUser,deleteUser }