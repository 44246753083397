<template>
  <div class="px-2">
    <h3 class="font-weight-bold">Users</h3>
    <hr class="mt-0 py-0 pl-1 mb-2" style="background-color: #e2e2e2" />
    <div class="d-flex align-items-center">
      <b-form inline style="width: 20vw" class="mb-2">
        <b-input-group prepend="Search" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input
            placeholder="Search Name"
            type="search"
            v-model="searchfield"
          ></b-form-input>
        </b-input-group>
      </b-form>
      <div class="ml-auto">
        <b-button
          variant="primary"
          class="mr-1"
          @click="createUser"
          v-analytics:click="['add_user',{element:'Settings'}]"
          id="tooltip-target-1"
        >
          <b-icon-plus></b-icon-plus> Add User
        </b-button>
        <b-button
          variant="outline-primary"
          @click="createRole"
          id="tooltip-target-2"
          v-analytics:click="['add_role',{element:'Settings'}]"
        >
          <b-icon-plus></b-icon-plus> User Role
        </b-button>
        <b-tooltip target="tooltip-target-1" triggers="hover">
          Click button to register a new user to the system
        </b-tooltip>
        <b-tooltip target="tooltip-target-2" triggers="hover">
          Click button to register a new role to the system
        </b-tooltip>
      </div>
    </div>

    <div class="d-flex">
      <b-col cols="8">
        <b-table
          :fields="fields"
          :items="items"
          :per-page="5"
          :current-page="currentPage"
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
          <template #cell(actions)="data">
            <b-dropdown size="sm" variant="light">
              <template #button-content>
                <b-icon-three-dots-vertical></b-icon-three-dots-vertical>
              </template>
              <b-dropdown-item variant="primary" 
              v-analytics:click="['edit_user_info',{element:'Settings'}]"
              @click="editUser(data.item)">
                <b-icon-pencil></b-icon-pencil> &nbsp; Edit
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                @click="removeUser(data.item.id)"
                v-analytics:click="['delete_user',{element:'Settings'}]"
                :disabled="data.item.role == 'administrator'"
              >
                <span class="text-danger">
                  <b-icon-trash></b-icon-trash> &nbsp; Delete
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="5"
          class="b-pagination-class"
        ></b-pagination>
      </b-col>
      <b-col cols="3" class="pl-2">
        <b-list-group>
          <b-list-group-item v-for="role in roles" :key="'role-' + role.id">
            <div class="d-flex">
              <div class="text-capitalize">
                {{ role.name }}
              </div>
              <div class="ml-auto">
                <b-link @click="showPermissions(role)" class="deco-none" v-analytics:click="['edit_user_role',{element:'Settings'}]">
                  <small><b-icon-pencil></b-icon-pencil> Edit</small>
                </b-link>

                <b-button
                  class="text-danger pl-2 text-decoration-none"
                  v-analytics:click="['delete_user_role',{element:'Settings'}]"
                  @click="removeRole(role.id)"
                  variant="link"
                  size="sm"
                >
                  &nbsp;<b-icon-trash></b-icon-trash> Delete
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </div>
    <b-modal
      id="roles-modal"
      hide-backdrop
      content-class="shadow"
      class="w-25"
      title="Roles"
      hide-footer
    >
      <b-form @submit.prevent="editRole">
        <b-form-group>
          <label>Name</label>
          <b-form-input
            v-model="active_role.name"
            type="text"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-table-simple>
            <b-thead>
              <b-th>Module</b-th>
              <b-th>Actions</b-th>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(resource, idx) in active_role.resources"
                :key="'perm' + idx"
              >
                <b-td class="text-capitalize">
                  {{ resource.name }}
                </b-td>
                <b-td>
                  <multiselect
                    v-model="resource.sactions"
                    :options="resource.actions"
                    :taggable="true"
                    multiple
                  ></multiselect>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-form-group>
        <b-button variant="primary" class="w-100 mx-auto" type="submit"
          v-analytics:click="['confirm_edit_role',{element:'Settings'}]"
          >SAVE CHANGES</b-button
        >
      </b-form>
    </b-modal>
    <add-role
      :roles="roles"
      @role-added="
        () => {
          getRoles();
        }
      "
    ></add-role>
    <add-user
      :roles="roles"
      @saved-user="
        () => {
          getUsers();
        }
      "
    ></add-user>
    <b-modal id="edit-user" hide-footer title="Edit User">
      <edit-user
        :roles="roles"
        :userx="active_user"
        @patch-user="
          () => {
            getUsers();
          }
        "
      ></edit-user>
    </b-modal>
  </div>
</template>
<style>
.dg-btn--ok {
  border-color:crimson;
  color:red;
}
</style>
<script>
import { getUsers, deleteUser } from "./actions.js";
import * as ModRole from "./libraries/roles.js";
import AddRole from "./components/AddRole.vue";
import AddUser from "./components/AddUser.vue";
import EditUser from "./components/EditUser.vue";
import Multiselect from "vue-multiselect";
import { can } from "@/modules/auth/utils.js";
export default {
  components: { AddRole, AddUser, Multiselect, EditUser },
  data() {
    let fields = [
      "name",
      "role",
      "username",
      { key: "last_login", label: "Last Login" },
      "actions",
    ];
    let items = [];
    return {
      fields,
      items,
      roles: [],
      active_role: {},
      active_user: {},
      searchfield: "",
      currentPage: 1,
      can,
    };
  },
  mounted() {
    this.getRoles();
    this.getUsers();
    this.$analytics.track("open_user_settings",{element:"System Users"})
  },
  methods: {
    editUser(item) {
      this.active_user = item;
      this.$bvModal.show("edit-user");
    },
    createRole() {
      this.$bvModal.show("add-role");
    },
    createUser() {
      this.$bvModal.show("register-user");
    },
    editRole() {
      ModRole.setRole(this.active_role).then(() => {
        this.$bvModal.hide("roles-modal");
      });
    },
    removeRole(role_id) {
      this.$dialog.confirm("Are you sure you'd like to delete the role?").then(() => {
        this.$analytics.track('confirm_delete_role',{element:"Settings"});
        ModRole.deleteRole(role_id).then(() => {
          this.getRoles();
          this.getUsers();
        });
      }).catch(()=>{
        this.$analytics.track('cancel_delete_role',{element:'Settings'});
      });
    },
    removeUser(user_id) {
      this.$dialog.confirm("Are you sure you'd like to delete this user").then(()=>{
        this.$analytics.track('confirm_delete_user',{element:'Settings'});
        deleteUser(user_id).then(() => {
          this.getUsers();
        });
      }).catch(()=>{
        this.$analytics.track('cancel_delete_user',{element:'Settings'});
      })
    },
    getRoles: ModRole.getRoles,
    showPermissions: ModRole.showPermissions,
    getUsers,
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.pagination.b-pagination-class {
  margin-top: 40px;
}
</style>