<template>
  <b-modal
    hide-backdrop
    content-class="shadow"
    class="w-25"
    title="Add Role"
    id="add-role"
    hide-footer
  >
    <b-form @submit.prevent="createRole">
      <b-form-group>
        <label>Name</label>
        <b-form-input v-model="role" type="text" required></b-form-input>
        <span v-show="duplicate_name" class="text-danger"><i>Duplicate name of role!</i></span>
      </b-form-group>
      <b-form-group>
        <b-table-simple>
          <b-thead>
            <b-th>Module</b-th>
            <b-th>Permissions</b-th>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(resource, idx) in resources" :key="'perm' + idx">
              <b-td class="text-capitalize">
                {{ resource.name }}
              </b-td>
              <b-td>
                <multiselect
                  v-model="resource.sactions"
                  :options="resource.actions"
                  :taggable="true"
                  multiple
                ></multiselect>
                <span v-show="!has_view(resource.sactions)" class="text-danger">
                  <i>{{resource.name}} must atleast have a view.</i>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>
      <b-button variant="primary" class="w-100 mx-auto" type="submit" 
       v-analytics:click="['confirm_add_role',{element:'Settings'}]"
      :disabled="duplicate_name || have_views(resources) || is_creating"
        >
        <b-spinner v-show="is_creating" small type="grow"></b-spinner>
        CREATE ROLE</b-button
      >
    </b-form>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { get_permissions, add_role } from "../libraries/add_user.js";
/**
 * @typedef Resource
 * @property {string} name
 * @property {Array<string>} sactions 
 */
export default {
  components: { Multiselect },
  props: ["roles"],
  data() {
    let role = "";
    let resources = [];
    return {
      role,
      resources,
      is_creating:false
    };
  },
  computed: {
    duplicate_name() {
      return this.roles.map((el) => el.name).includes(this.role.toLowerCase());
    },
  },
  mounted() {
    this.get_permissions();
  },
  methods: {
    get_permissions,
    createRole() {
      this.is_creating = true;
      add_role(this.role, this.resources).then(() => {
        this.$bvModal.hide("add-role");
        this.$emit("role-added");
        this.is_creating = false;
      });
    },
    /**
     * @param {Array<string>} actions
     */
    has_view(actions){
      return actions.includes('view');
    },
    /**
     * @param {Array<Resource>} resources
     */
    have_views(resources){
      return resources.some(el=>!el.sactions.includes('view'));
    }
  },
};
</script>

<style>
</style>