import { axiosApiInstance, axiosApiInst } from "@/plugins/axios.js"
const SETTINGS_BACKEND = process.env.VUE_APP_BACKEND + "/settings"
const ROLES_BACKEND = SETTINGS_BACKEND + "/roles";


function get_permissions() {
    axiosApiInstance({
        url: ROLES_BACKEND + "/general_permissions"
    }).then(res => {
        this.resources = res.data.map(el => {
            return { ...el, sactions: ["view"] }
        });
    });
}
/**
 * 
 * @param {Array<{name:string,actions:Array<string>,sactions:Array<string>}>} permissions
 */
function get_selected(permissions) {
    return permissions.filter(el => { return el.sactions.length > 0; }).map(el => { return { name: el.name, actions: el.sactions } });
}
/**
 * 
 * @param {string} name 
 * @param {Array<{name:string,actions:Array<string>,sactions:Array<string>}>} permissions 
 */
function add_role(name, permissions) {
    return axiosApiInstance({
        url: ROLES_BACKEND + "/",
        method: 'post',
        data: { name, permissions: get_selected(permissions) }
    });
}
/**
 * @typedef User 
 * @property {string} name
 * @property {string} role
 * @property {string} password
 * @property {string} phone_no
 */
/**
 * 
 * @param {User} user
 * @returns {Promise<void>}
 */
async function save_user(user) {
    const res = await axiosApiInst({
        url: SETTINGS_BACKEND + "/sysusers/",
        method: "post",
        data: user
    });
    return res;
}
export { get_permissions, add_role, save_user }