<template>
  <b-modal
    hide-backdrop
    content-class="shadow"
    class="w-25"
    title="Register User"
    id="register-user"
    hide-footer
  >
    <b-overlay :show="saving" variant="primary">
      <b-form @submit.prevent="createUser">
        <b-form-group>
          <label>Name</label>
          <b-form-input type="text" v-model="user.name" required></b-form-input>
        </b-form-group>
        <b-form-group>
          <label>Email</label>
          <b-form-input
            type="email"
            v-model="user.username"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Phone Number">
          <b-form-input
            v-model="user.phone_no"
            type="tel"
            pattern="[0-9]{10}"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label>User Role</label>
          <b-form-select
            class="mb-1 text-capitalize"
            v-model="user.role"
            required
          >
            <b-form-select-option
              :value="role.name"
              v-for="role in roles"
              :key="'r-' + role.id"
              ><span>{{ role.name }}</span></b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-button
          variant="primary"
          class="w-100"
          type="submit"
          v-analytics:click="['confirm_add_user',{element:'Settings'}]"
          :disabled="user.username < 8"
        >
          REGISTER USER</b-button
        >
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import { save_user } from "../libraries/add_user.js";
export default {
  props: ["roles"],
  data() {
    let user = {
      name: "",
      username: "",
      phone_no: "",
      role: "",
    };
    return {
      user,
      saving: false,
    };
  },
  methods: {
    createUser() {
      this.saving = true;
      save_user(this.user)
        .then(() => {
          this.saving = false;
          this.user = {};
          this.$bvToast.toast("User should visit email to complete registration", {
            title: "User Registered",
            variant: "success",
          });
          this.$emit("saved-user");
          this.$bvModal.hide("register-user");
        })
        .catch((err) => {
          this.saving = false;
          const data = err.response.data;
          if (err.response.status == 409) {
            this.$bvToast.toast(data, {
              title: "Duplicate Field",
              variant: "danger",
              autoHideDelay: 5000,
            });
          }
        });
    },
  },
};
</script>

<style>
</style>