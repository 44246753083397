<template>
  <b-form @submit.prevent="editUser">
    <b-form-group>
      <label>Name</label>
      <b-form-input type="text" v-model="user.name" disabled></b-form-input>
    </b-form-group>
    <b-form-group>
      <label>Email</label>
      <b-form-input type="email" v-model="user.username" disabled></b-form-input>
    </b-form-group>
    <b-form-group>
      <label>User Role</label>
      <b-form-select class="mb-1 text-capitalize" v-model="user.role" required>
        <b-form-select-option
          :value="role.name"
          v-for="role in editable_roles"
          :key="'r-' + role.id"
          ><span>{{
            role.name
          }}</span></b-form-select-option
        >
      </b-form-select>
    </b-form-group>
    <b-button
      variant="primary"
      class="w-100"
      type="submit"
      v-analytics:click="['confirm_edit_user',{element:'Settings'}]"
    >
      EDIT USER</b-button
    >
  </b-form>
</template>

<script>
import { edit_user } from "../libraries/edit_user.js";
export default {
  props: ["userx", "roles"],
  mounted() {
    this.user = this.userx;
  },
  computed:{
    editable_roles(){
      return this.roles.filter((role) => role.fixed!==true)
    }
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    editUser() {
      edit_user(this.user).then(() => {
        this.$bvModal.hide("edit-user");
        this.$emit("patch-user");
      });
    },

  },
};
</script>

<style>
</style>