import { axiosApiInstance } from "@/plugins/axios.js"
const ROLES_BASE = "/settings/roles";
function getRoles() {
    axiosApiInstance({
        url: process.env.VUE_APP_BACKEND + ROLES_BASE + "/"
    }).then(res => {
        this.roles = res.data.filter(rl=>{return rl.name!="administrator"});
    });
}
/**
 * @param {{id:string,name:string,resources:Array<{id:string,
 * name:string,actions:Array<string>,last_login:string}>}} role
 * @returns {{id:string,name:string,resources:Array<{name:string,actions:Array<string>}>}}
 */
function clean_role(role) {
    role["resources"] = role["resources"].map(el => {
        // eslint-disable-next-line no-unused-vars
        let { last_login, ...rest_of } = el;
        last_login = undefined;
        return rest_of;
    })
    return role;
}
/**
 * 
 * @param {{id:string,name:string,resources:Array<{name:string,actions:Array<string>}>}} role 
 */
function setRole(role) {
    return axiosApiInstance.patch(
        process.env.VUE_APP_BACKEND + ROLES_BASE + "/",
        clean_role(role)
    );
}

/**
 * 
 * @param {string} role_id 
 */
function deleteRole(role_id) {
    return axiosApiInstance.delete(
        process.env.VUE_APP_BACKEND + ROLES_BASE + "/" + role_id
    )
}

function showPermissions(role) {
    axiosApiInstance({
        url: process.env.VUE_APP_BACKEND + ROLES_BASE + "/" + role.id,
    }).then(res => {
        let info = res.data;
        this.active_role = { ...role, ...{ resources: info } };
        this.$nextTick(() => {
            this.$bvModal.show("roles-modal");
        });
    });
}
export { deleteRole, setRole, getRoles, showPermissions }