import { axiosApiInstance } from "@/plugins/axios.js"
function transform_user(user){
    // eslint-disable-next-line no-unused-vars
    const { last_login,name,username,...userx } = user
    const params = new URLSearchParams(userx);
    return params.toString()
}
function edit_user(user) {
    return axiosApiInstance.patch(
        process.env.VUE_APP_BACKEND + "/settings/sysusers/",
        transform_user(user)
    );
}
export { edit_user }
